var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { style: _vm.wrapperStyle }, [
    _vm.showLoader
      ? _c(
          "div",
          {
            staticClass:
              "d-flex flex-column align-center justify-center text-center",
            staticStyle: { height: "100%" },
          },
          [
            _c(
              "div",
              [
                _c("p", [_vm._v("Application is launching powered by:")]),
                _c("div", [
                  _c("span", { staticClass: "caption" }, [
                    _vm.resourceLimit.cpu !== 0 &&
                    _vm.deployment.node_pool === "nv-app"
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.resourceLimit.cpu) +
                              " CU = " +
                              _vm._s(_vm.resourceLimit.cpu) +
                              " vCPU / " +
                              _vm._s(_vm.resourceLimit.mem) +
                              " GB memory "
                          ),
                        ])
                      : _vm._e(),
                    _vm.deployment.node_pool !== "nv-app"
                      ? _c(
                          "div",
                          { staticClass: "secondary--text font-weight-bold" },
                          [
                            _vm._v(
                              " Procuring your computing resources. ETA 5-10 minutes. "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("v-progress-linear", {
                  staticClass: "mt-5 mb-1",
                  attrs: {
                    indeterminate: "",
                    color: "primary",
                    rounded: "",
                    height: "6",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-stepper",
              { attrs: { value: _vm.loadStatus } },
              [
                _c(
                  "v-stepper-header",
                  [
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.loadStatus > 1, step: "1" } },
                      [_vm._v("Allocating")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.loadStatus > 2, step: "2" } },
                      [_vm._v("Configuring")]
                    ),
                    _c("v-divider"),
                    _c("v-stepper-step", { attrs: { step: "3" } }, [
                      _vm._v("Booting"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-stepper-items",
                  [
                    _c("v-stepper-content", { attrs: { step: "1" } }, [
                      _vm.deployment.node_pool !== "nv-app"
                        ? _c("span", [
                            _vm._v(
                              "Allocating computing resources is underway."
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "Allocating computing resources is underway. Due to high demand, it will take a bit longer."
                            ),
                          ]),
                    ]),
                    _c("v-stepper-content", { attrs: { step: "2" } }, [
                      _vm._v(" Finalizing software configuration. "),
                      _vm.deployment.node_pool !== "nv-app"
                        ? _c("span", [
                            _vm._v(
                              "Due to high demand, it will take a bit longer."
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "3" } },
                      [
                        _vm._v(" Booting your application. "),
                        _c("br"),
                        _c(
                          "v-icon",
                          {
                            staticClass: "mt-2",
                            attrs: { color: "success", large: "" },
                          },
                          [_vm._v("done")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.appLongWait
              ? _c(
                  "div",
                  { staticStyle: { width: "500" } },
                  [
                    _c(
                      "v-carousel",
                      {
                        staticStyle: { "margin-bottom": "-200px" },
                        attrs: {
                          height: "200",
                          "hide-delimiters": "",
                          "show-arrows": false,
                          cycle: true,
                        },
                      },
                      [
                        _vm.deployment.app_type === _vm.appTypes.RSTUDIO.name
                          ? _c(
                              "v-carousel-item",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      "colored-border": "",
                                      color: "blue",
                                      border: "left",
                                      "max-width": "500",
                                      light: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Hint: for heavier / long running computations in RStudio, "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          "text-decoration": "none",
                                        },
                                        attrs: {
                                          href: "https://docs.nuvolos.cloud/user-guides/research-guides/high-performance-computing#rstudio",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(" we recommend RStudio jobs ")]
                                    ),
                                    _vm._v(" . "),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.deployment.app_type === _vm.appTypes.JUPYTER.name
                          ? _c(
                              "v-carousel-item",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      "colored-border": "",
                                      color: "blue",
                                      border: "left",
                                      "max-width": "500",
                                      light: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Hint: you can add additional kernels/environments (e.g. R, Python) to JupyterLab by, "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          "text-decoration": "none",
                                        },
                                        attrs: {
                                          href: "https://docs.nuvolos.cloud/user-guides/research-guides/high-performance-computing#jupyterlab",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(" following our guide ")]
                                    ),
                                    _vm._v(" . "),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isGuac && _vm.loadStatus < 3
                          ? _c(
                              "v-carousel-item",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      "colored-border": "",
                                      color: "blue",
                                      border: "left",
                                      "max-width": "500",
                                      light: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Hint: You can switch windows inside the application using SHIFT + TAB "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showDropboxHint && _vm.loadStatus < 3
                          ? _c(
                              "v-carousel-item",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      "colored-border": "",
                                      color: "blue",
                                      border: "left",
                                      "max-width": "500",
                                      light: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c("div", [
                                          _vm._v(" Hint: You can "),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "text-decoration": "none",
                                              },
                                              attrs: {
                                                href: "https://docs.nuvolos.cloud/features/file-system-and-storage/synchronize-with-dropbox",
                                                target: "_blank",
                                              },
                                            },
                                            [_vm._v(" sync your files ")]
                                          ),
                                          _vm._v(" on Nuvolos with "),
                                        ]),
                                        _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("@/assets/Dropbox.svg"),
                                            "max-width": "100",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.appLongWait && _vm.deployment.node_pool === "nv-app"
              ? _c(
                  "div",
                  { staticClass: "caption mt-4 grey--text" },
                  [
                    _vm._v(
                      " If your application doesn't load in a few minutes, please try "
                    ),
                    _c("br"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-lowercase",
                        attrs: { text: "", color: "primary", small: "" },
                        on: { click: _vm.restartApp },
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v("power_settings_new")]
                        ),
                        _vm._v(" restarting "),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-2" }, [_vm._v("or")]),
                    _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-lowercase",
                            attrs: { text: "", color: "primary", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.showNewMessageIntercom(
                                  "Hi Nuvolos Team, \nI would like to ask your help regarding the following:\n",
                                  _vm.userInfo
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { small: "" } },
                              [_vm._v("support")]
                            ),
                            _vm._v(" ask for help "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.proxy_url
      ? _c("iframe", {
          style: _vm.iframeStyle,
          attrs: {
            src: _vm.proxy_url,
            id: _vm.deployment.session_id,
            allow: "clipboard-read; clipboard-write",
          },
          on: { load: _vm.hideLoader, mouseenter: _vm.focusIframe },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }