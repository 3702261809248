var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.nuvolosIframe },
    [
      _vm.createdDeployments.length === 0
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-column justify-center align-center",
              staticStyle: { height: "100%", width: "100%" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-center justify-center text-center",
                },
                [
                  _vm.launchingApp
                    ? _c(
                        "div",
                        [
                          _c("p", [
                            _vm._v("Preparing the application for launch"),
                          ]),
                          _c("v-progress-linear", {
                            staticClass: "my-3",
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              rounded: "",
                              height: "6",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.launchingApp
                    ? _c(
                        "v-stepper",
                        { attrs: { value: 1 } },
                        [
                          _c(
                            "v-stepper-header",
                            [
                              _c("v-stepper-step", { attrs: { step: "1" } }, [
                                _vm._v("Allocating"),
                              ]),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "2" } }, [
                                _vm._v("Configuring"),
                              ]),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "3" } }, [
                                _vm._v("Booting"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.launchingApp && _vm.appIssue
                    ? _c(
                        "div",
                        [
                          _vm.appIssue === "resource"
                            ? [
                                _vm._v(
                                  " This application was stopped due to inactivity or exceeding available resources, please try "
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-lowercase",
                                    attrs: {
                                      text: "",
                                      color: "primary",
                                      small: "",
                                    },
                                    on: { click: _vm.restartApp },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v("power_settings_new")]
                                    ),
                                    _vm._v(" restarting "),
                                  ],
                                  1
                                ),
                                _vm._v(" the application. "),
                              ]
                            : _vm._e(),
                          _vm.appIssue === "comms"
                            ? [
                                _vm._v(
                                  " This application has become inaccessible. You might need to "
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-lowercase",
                                    attrs: {
                                      text: "",
                                      color: "primary",
                                      small: "",
                                    },
                                    on: { click: _vm.restartApp },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v("power_settings_new")]
                                    ),
                                    _vm._v(" restart "),
                                  ],
                                  1
                                ),
                                _vm._v(" the application. "),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.launchingApp && !_vm.appIssue
                    ? _c(
                        "timeout-handler",
                        { attrs: { timeout: 5000 } },
                        [
                          _vm._v(
                            " There might be a temporary issue with launching this application, please try "
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-lowercase",
                              attrs: { text: "", color: "primary", small: "" },
                              on: { click: _vm.restartApp },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("power_settings_new")]
                              ),
                              _vm._v(" restarting "),
                            ],
                            1
                          ),
                          _vm._v(" the application. "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.openedDeployments.length
        ? _c(
            "div",
            { staticStyle: { height: "100%", width: "100%" } },
            _vm._l(_vm.openedDeployments, function (item) {
              return _c("AppIframe", {
                key: item.session_id,
                attrs: {
                  deployment: item,
                  isActive:
                    _vm.$route.name === "app-open" &&
                    item.session_id === _vm.$route.params.sessionId,
                  appType: item.app_type,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.showCredentialModal,
            callback: function ($$v) {
              _vm.showCredentialModal = $$v
            },
            expression: "showCredentialModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("University Login Required")]),
              _c(
                "v-card-text",
                [
                  _vm._v(" Please provide your "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("university credentials"),
                  ]),
                  _vm._v(" to establish a connection to the licence server. "),
                  _c(
                    "v-alert",
                    { staticClass: "my-2", attrs: { type: "info" } },
                    [
                      _vm._v(
                        " Irrespective of how you log into Nuvolos, please provide your university credentials here. Wrong credentials will result in the licensed application not starting. "
                      ),
                    ]
                  ),
                  _vm.enteredEmailAndNotUsername
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          " It appears you entered your e-mail address instead of your login name. Please check your login name "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://my-account.unige.ch/my-account/web/home",
                              target: "_blank",
                            },
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(" . "),
                      ])
                    : _vm._e(),
                  _c(
                    "v-form",
                    {
                      staticClass: "text-center",
                      attrs: { onSubmit: "return false;" },
                      on: {
                        submit: function ($event) {
                          return _vm.launchAppIfNeeded(_vm.$route.params.aid)
                        },
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Username", outlined: "" },
                        model: {
                          value: _vm.appUsername,
                          callback: function ($$v) {
                            _vm.appUsername = $$v
                          },
                          expression: "appUsername",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "University Password",
                          type: "password",
                          outlined: "",
                        },
                        model: {
                          value: _vm.appPwd,
                          callback: function ($$v) {
                            _vm.appPwd = $$v
                          },
                          expression: "appPwd",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 font-weight-bold",
                          attrs: {
                            color: "primary",
                            type: "submit",
                            width: "100%",
                            block: "",
                            disabled: _vm.enteredEmailAndNotUsername,
                          },
                        },
                        [_vm._v(" Launch App ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.goToOverview },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }